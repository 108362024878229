<template>
  <v-main>
    <template v-if="viewDataIsLoaded">
      <v-app-bar
        fixed
        elevate-on-scroll
        dark
        color="primary"
      >
        <v-btn
          icon
          @click="goHome()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ event.evn_str_title }} - bokningar</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!--
          <v-btn
            dark
            text
            @click="saveEvent"
          >
            Spara
          </v-btn>
          -->
        </v-toolbar-items>
      </v-app-bar>
      <v-container>
        <v-row class="pt-16">
          <v-col class="pt-4 pb-16">
            <div
              v-for="(bookedDay, bookedDay_index) of bookedDays"
              :key="bookedDay_index"
              :id="'bookedDay-' + bookedDay_index"
            >
              <div class="text-h6 text-capitalize">{{ bookedDay.friendlyDate }}</div>
              <div
                v-for="(saloon, saloon_index) of bookedDay.saloons"
                :key="saloon_index"
              >
                <div class="d-flex justify-space-between">
                  <div class="text-body-1">{{ saloon.name }}</div>
                  <div class="hidden-print">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="showEmails(bookedDay, saloon)"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-email-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Visa e-postadresser</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="printSection('bookedDay-' + bookedDay_index)"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </template>
                      <span>Skriv ut</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-simple-table
                  dense
                  class="xSimpleTable mb-8"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Tid
                        </th>
                        <th
                          v-if="hasClientCount"
                          class="text-left"
                        >
                          Personer
                        </th>
                        <th class="text-left">
                          Bokare
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(time, index) of saloon.times"
                        :key="index"
                      >
                        <td class="no-wrap">{{ time.slot }}</td>
                        <td v-if="hasClientCount">
                          {{ time.totalClientCount }} st
                        </td>
                        <td>
                          <div
                            v-for="(booking, index) of time.bookings"
                            :key="index"
                            @click="showBooking(booking)"
                            class="xListButton"
                          >
                            {{ booking.bookedBy }}
                            {{ hasClientCount ? ' (' + booking.clientCount + ' st)' : ''}}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!--dialogEmails-->
    <v-dialog
      v-model="dialogEmails"
      width="800"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ emailsSaloon }} - {{ emailsDate }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            :value="emails"
            auto-grow
            ref="emails"
            @focus="$event.target.select()"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogEmails = false"
          >
            Stäng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--loader-->
    <v-overlay
      :value="loading"
      opacity="0.8"
      z-index="1001"
    >
      <v-progress-circular
        :size="60"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import axios from 'axios'
import SnackBar from '../components/SnackBar'
import { snackBarBus } from '../main'
import { mapGetters } from 'vuex'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'
import { dateMixin } from '../mixins/date'
import { arrayMixin } from '../mixins/array'

export default {
  name: 'EventBookings',
  components: {
    'app-snack-bar': SnackBar,
  },
  mixins: [generalMixin, urlMixin, dateMixin, arrayMixin],
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    loading: false,
    viewDataIsLoaded: false,
    event: null,
    bookedDays: [],
    hasClientCount: null,
    dialogEmails: false,
    emailsDate: '',
    emails: '',
    emailsSaloon: ''
  }),
  computed: {
    ...mapGetters(['agent']),
  },
  watch: {
  },
  methods: {
    getData() {
      console.log('getData')
      if (this.loading) {
        return
      }
      this.loading = true
      let relUrl = '/admin/event-bookings/' + this.agent + '/' + this.id
      const url = this.url_composeApiUrl(relUrl)
      console.log('url', url)
      axios.get(url)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onData(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
    onData(data) {
      this.event = data.event
      this.bookedDays = data.bookedDays
      this.hasClientCount = data.event.evn_enm_multiple_appointment_type == 'client'
      //final
      this.viewDataIsLoaded = true
    },
    goHome() {
      //go to home with event tab active, like this
      this.$router.replace({ name: 'home', hash: '#events' })
    },
    showBooking(booking) {
      this.$router.push({ name: 'booking', params: { id: booking.id }, query: { referer: 'event' } })
    },
    showEmails(bookedDay, saloon) {
      this.emailsDate = bookedDay.date
      this.emailsSaloon = saloon.name
      this.emails = saloon.emails.join('; ')
      this.dialogEmails = true
    },
    printSection(id) {
      this.$htmlToPaper(id);
    }
  },
  created() {
    this.getData()
  }
}
</script>
